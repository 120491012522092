// import { qa as dataAccessAngularK12Env } from '@carnegie-learning/data-access-angular/env';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.production.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  // ...dataAccessAngularK12Env,
  PREFERENCES_API_URI:
    '//apps.qa-cli.net/services-k12-licensing-webapp/preferences/',
  apiUrl: '/api',
  loginRedirectUrl: '/demo/launch',
  production: false,
  STREAM_PLAYER_API_DOMAIN: 'mathstream.qa-cli.net',
  STREAM_PLAYER_ORIGIN: 'https://apps.qa-cli.net',
  STREAM_CAP_PLAYER_ORIGIN: 'https://cap.qa-cli.net',
  MATHIA_ORIGIN: 'https://www.qa-cli.net',
  ASSIGNMENT_PROGRESS_TRACKER_API_URI:
    'https://apt.microservices.qa-cli.net/services-k12-apt-webapp/assignmentprogress/',
  PENDO_API_KEY: '%PENDO_API_KEY%',
  RUSH_API_URI: 'https://www.qa-cli.com/rush',
  CURRICULUM_API_URI: 'https://apps.qa-cli.net/curriculum',
  USERS_API_URI: 'https://www.qa-cli.net/services-fido/users',
  AUTH_API_URI: 'https://www.qa-cli.net/services-fido',
  CUSTOMERS_API_URI: 'https://www.qa-cli.net/services-fido/customers',
  appVersion: '%VERSION%',
  appName: 'MATHStream',
  CALIPER_API_URI: 'https://mathstream.qa-cli.net/api/caliper-events',
  CALIPER_SENSOR_ID: 'dev.tutor-stream',
  CALIPER_APPLICATION_URI: 'https://apps.qa-cli.net/clc',
  LEARNOSITY_API_URI: 'https://items.learnosity.com/?v2024.1.LTS',
  MOTIVATION_API_URI: 'https://motivation.microservices.qa-cli.net/api',
  versionedConfigUrlTemplate:
    'https://www.qa-cli.net/services-k12-webapp-%ver%/config',
  CONFIG_FALLBACK_URI: 'https://www.qa-cli.net/config',
  LOGOUT_REDIRECT_FALLBACK_URI: 'https://www.qa-cli.net/portal/#/portal/logout',
  CURATE_API_URI: 'https://curate-api.qa-cli.com/curate',
  EVIDENCE_STATEMENTS_API_URI:
    'https://evidence-statements.microservices.qa-cli.net',

  // Micro Frontend Config
  MODULE_FEDERATION_MANIFEST_PATH:
    './assets/module-federation/module-federation.manifest.dev.json',

  // UpGrade Config
  upgrade: {
    host: 'https://upgradeapi.qa-cli.com',
    context: 'mathstream-qa'
  },

  // LiveHintAi Config
  liveHintAi: {
    appContext: 'mathstream',
    course: 'Algebra+1',
    host: 'https://livehint.qa-cli.net',
    module: 'M1',
    page: 101,
    question: 1
  },

  // Algolia Config
  ALGOLIA_INDEX_NAME: 'AssetsSearch_StagingV7',
  ALGOLIA_APP_ID: 'testingBUNIOZENB6',
  ALGOLIA_APP_KEY: '%ALGOLIA_API_KEY%',

  // Landbot configuration
  landbotConfigUrl:
    'https://storage.googleapis.com/landbot.online/v3/H-1598892-XQATV1BQG3NI7L1F/index.json',
  LTI_LEGACY_BASE_PATH: '/lti/client/gamathqa'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
